<script setup lang="ts">
import { usePageStore } from '@voix/store/pageStore'

defineLayout({
  label: 'Barbie',
})

useAtlantisHead()

useSeoMeta({
  title: 'Bahamas Vacation Resort | Luxury Resort | Atlantis Bahamas',
  ogTitle: 'Bahamas Vacation Resort | Luxury Resort | Atlantis Bahamas',
  description: 'Experience Atlantis Paradise Island resorts entertainment and adventures. See what activities and amenities this Bahamas vacation destination has to offer.',
  ogDescription: 'Experience Atlantis Paradise Island resorts entertainment and adventures. See what activities and amenities this Bahamas vacation destination has to offer.',
  ogImage: 'https://www.atlantisbahamas.com/storage/media/atlantis-property-shot-royal-hero-sky.jpg',
  ogUrl: 'https://www.atlantisbahamas.com',
})

// Generated much of this at https://realfavicongenerator.net
useHead({
  htmlAttrs: {
    lang: 'en-US',
  },
  meta: [
    { name: 'msapplication-TileColor', content: '#000000' },
    { name: 'msapplication-config', content: '/favicons/atlantisbahamas/browserconfig.xml' },
    { name: 'theme-color', content: '#000000' },
  ],
  link: [
    { rel: 'apple-touch-icon', sizes: '180x180', href: '/favicons/atlantisbahamas/apple-touch-icon.png' },
    { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicons/atlantisbahamas/favicon-32x32.png' },
    { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicons/atlantisbahamas/favicon-16x16.png' },
    { rel: 'manifest', href: '/favicons/atlantisbahamas/site.webmanifest' },
    { rel: 'mask-icon', href: '/favicons/atlantisbahamas/safari-pinned-tab.svg', color: '#5bbad5' },
    { rel: 'shortcut icon', href: '/favicons/atlantisbahamas/favicon.ico' },
    { rel: 'stylesheet', href: 'https://use.typekit.net/vfv1wda.css' },
  ],
  script: [
    { src: '//fast.wistia.com/assets/external/E-v1.js', async: true },
  ],
})

const route = useRoute()
const pageStore = usePageStore()
const isVoixTemplatizer = inject('isVoixTemplatizer', false)
const config = useRuntimeConfig()

const siteId = pageStore.currentPage?.site_id || 1

const { data: mainMenu }: { data: Record<string, any> } = await useFetch(
  `${config.public.voixApiBaseUrl}/api/sites/${siteId}/menus/Glue%20Main%20Navigation`,
)
const { data: footerMenu }: { data: Record<string, any> } = await useFetch(
  `${config.public.voixApiBaseUrl}/api/sites/${siteId}/menus/Glue%20Footer%20Navigation`,
)
const { data: legalMenu }: { data: Record<string, any> } = await useFetch(
  `${config.public.voixApiBaseUrl}/api/sites/${siteId}/menus/Legal%20Footer%20Links`,
)

const nochrome = route.query.nochrome
</script>

<template>
  <div id="app" class="relative min-h-screen barbie-theme">
    <div id="glue" class="text-zinc-700 bg-white" :class="{ nochrome }">
      <div class="relative z-40">
        <div id="portal-modal" />
      </div>

      <div class="flex justify-center">
        <div class="relative z-10 big-container overflow-hidden bg-barbie-50 text-barbie-800">
          <div v-if="!nochrome && pageStore.currentPage?.site_id !== 4 && !isVoixTemplatizer">
            <!-- TODO: Missing ta_track_num -->
            <BarbieDesktopNavigation class="hidden xl:block" :menu="mainMenu" />
            <BarbieMobileNavigation class="xl:hidden" :menu="mainMenu" />
          </div>

          <div
            class="relative min-h-[50vh]"
          >
            <slot />
          </div>

          <div v-if="!nochrome && !isVoixTemplatizer" class="relative z-0">
            <BarbieFooter :menu="footerMenu.data" :legal-menu="legalMenu.data" />
            <AtlantisbahamasAlert />
          </div>
        </div>
      </div>
      <GlobalUIHelpBot />
    </div>
  </div>
</template>

<style lang="scss">
@import '~/assets/sass/barbie.scss';
</style>
